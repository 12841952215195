<template>
    <div class="h-100">
        <bip-card v-if="bipProjectData"
                  :showForm="showForm"
                  :location="regLocation"
                  :regionList="regionList"
                  :userAbps="userAbps"
                  :bip="bipProjectData">
        </bip-card>
    </div>
</template>

<script>
    import 'bootstrap/dist/css/bootstrap.css';
    import 'bootstrap-vue/dist/bootstrap-vue.css';
    import store from '@/services/store';
    import BipCard from '@/modules/budget/bip/bip-card.vue';
    import {BipProjectDataClass} from "./bip-types";

    export default {
        name: 'bip-card-out',
        components: {BipCard},
        data() {
            return {
                regLocation: null,
                regionList: [],
                userAbps: [],

                bipProjectData: null,
                userUiid: null,
                code: null,
                showForm: false
            };
        },

        async mounted() {
            this.userUiid = await store.state.user.sub;
            this.code = await store.state._instanceCode;

            await this.getLocation();
            await this.loadRegionList();
            await this.loadUserAbps();

            const urlArray = location.hash.split('?')
            if (urlArray.length>0) {
                const queryString = urlArray[1];
                const urlParams = new URLSearchParams(queryString);
                const bip_code = urlParams.get('bip_code');
                const variant = urlParams.get('variant');
                if (bip_code !== null && variant !== null) {
                   await this.loadBip(bip_code, variant);
                }
            }
            this.showForm = true;
        },

        methods: {
            async getLocation() {
                try {
                    if (this.code !== null) {
                        await fetch('/api-py/get-budget-obl/' + this.code)
                            .then(response => response.json())
                            .then(json => {
                                this.regLocation = json;
                                console.log('location bip-free', this.regLocation);
                            });
                    } else {
                        return;
                    }
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса getLocation', error.toString());
                }
            }, // запрос локации по глобальному коду области

            async loadBip(bip_code, variant) {
                try {
                    let response = await fetch('/api-py/bip-card/' + bip_code + '/' + variant);
                    response = await response.json();
                    this.bipProjectData = response;
                    console.log('form', this.bipProjectData);
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса loadBip', error.toString());
                    return;
                }

            }, // загрузка списка БИПов

            async loadRegionList() {
                if (this.code === null) {
                    await this.getLocation();
                } else {
                    let response = await fetch('/api-py/get-kato-regions/' + this.regLocation.code);
                    response = await response.json();
                    for (const region of response) {
                        if (region.code.substring(2, 3) !== '0') {
                            try {
                                let locals = await fetch('/api-py/get-kato-localities/' + region.code.substring(0, 4));
                                locals = await locals.json();
                                locals.sort(this.sortByField('name_ru'));
                                this.$set(region, 'localityList', locals);
                                this.regionList.push(region);
                            } catch (error) {
                                this.makeToast('danger', 'Ошибка запроса loadRegionList', error.toString());
                            }
                        }
                    }
                    this.regionList.sort(this.sortByField('name_ru'));
                    console.log('regionList', this.regionList);
                }
            }, // загрузка районов по области

            async loadUserAbps() {
                this.userAbps = [];
                try {
                    if (this.userUiid) {
                        let response = await fetch('/api-py/user-abp/' + this.userUiid);
                        response = await response.json();
                        for (const abp of response) {
                            this.userAbps.push(abp.abp);
                        }
                    }
                } catch (error) {
                    this.makeToast('danger', 'Ошибка загрузки ABP пользователя', error.toString());
                }
            }, // перечень доступных АБП пользователя

            makeToast(variant, title, tostbody) {
                this.$bvToast.toast(tostbody, {
                    title: title,
                    variant: variant,
                    toaster: 'b-toaster-top-center',
                    autoHideDelay: 5000,
                    appendToast: true
                });
            }, // сообщение

            sortByField(field) {
                return (a, b) => (a[field] > b[field] ? 1 : -1);
            } // сортирует по заданному полю
        }
    };
</script>